import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const Config = {
  apiKey: "AIzaSyDRzW_w7bnv3gQCT9yM5qbHIV-vbYD03T4",
  authDomain: "tubeflix-7f8e8.firebaseapp.com",
  projectId: "tubeflix-7f8e8",
  storageBucket: "tubeflix-7f8e8.appspot.com",
  messagingSenderId: "809507455214",
  appId: "1:809507455214:web:f3bf96c8dbbd3edb3133ff",
  measurementId: "G-VZBMRWSXPQ"
};

export const CreateUserProfileDocument = async (userAuth, additionalData) => {
	if (!userAuth) return;

	const userRef = firestore.doc(`users/${userAuth.uid}`);

	const snapShot = await userRef.get();

	if (!snapShot.exists) {
		const { displayName, email } = userAuth;
		const createdAt = new Date();

		try {
			await userRef.set({
				displayName,
				email,
				createdAt,
				...additionalData,
			});
		} catch (error) {
			console.log(`${error} User Can't be registered`);
		}
	}
	return userRef;
};

firebase.initializeApp(Config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
