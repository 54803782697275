const MovieActionTypes = {
	SET_MOVIE_DATA_START: 'SET_MOVIE_DATA_START',
	SET_MOVIE_DATA: 'SET_MOVIE_DATA',
	SET_MOVIE_GRID_DATA: 'SET_MOVIE_GRID_DATA',
	SET_MOVIE_DATA_SUCCESS: 'SET_MOVIE_DATA_SUCCESS',
	SET_MOVIE_ADDITIONAL_DATA_SUCCESS: 'SET_MOVIE_ADDITIONAL_DATA_SUCCESS',
	SET_MOVIE_ADDITIONAL_DATA: 'SET_MOVIE_ADDITIONAL_DATA',
	SET_BANNER_DATA: 'SET_BANNER_DATA',
};

export default MovieActionTypes;
