import React from 'react';
import { IMAGE_BASE_URL, BACKDROP_SIZE, POSTER_SIZE } from '../../Config/config';
import './ItemPage.scss';
import { connect } from 'react-redux';
import imdb from '../../Assets/imdb.png';
import star from '../../Assets/star.png';
import { selectMovieCast, selectMovieVideos } from '../../Redux/Movie/movie-selectors';
import ItemPageFooter from '../ItemPageFooter/ItemPageFooter';
import { getAdditionalMovieData } from '../../Redux/Movie/movie-actions';
import { getAdditionalTVData } from '../../Redux/TVShow/tv-actions';
import Fade from 'react-reveal/Fade';
import YouTube from 'react-youtube';
import {
	TwitterShareButton,
	TwitterIcon,
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon,
} from 'react-share';

class ItemPage extends React.Component {
	componentDidMount() {
		return this.props.movies
			? this.props.dispatch(getAdditionalMovieData(this.props.item.id))
			: this.props.dispatch(getAdditionalTVData(this.props.item.id));
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps);
	}

	render() {
		const shareUrl = window.location.href;
		const shareTitle = 'Tubeflix';
		const { item, movies, tvshow } = this.props;
		const { title, name, overview, backdrop_path, poster_path, vote_average } = item;
		const background = `${IMAGE_BASE_URL}${BACKDROP_SIZE}${backdrop_path}`;
		const poster = `${IMAGE_BASE_URL}${POSTER_SIZE}${poster_path}`;
		const opts = {
			height: '800',
			width: '1500',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 1,
				loop: 1,
				controls: 0,
			},
		};

		const convertMinsToHrsMins = (mins) => {
			let h = Math.floor(mins / 60);
			let m = mins % 60;
			h = h < 10 ? '' + h : h;
			m = m < 10 ? '0' + m : m;
			return `${h}H ${m}M`;
		};
		return (
			<div className="item-page">
				<div src={`${background}`} alt="background" className="item-page__bg" />
				<div className="item">
					<Fade>
						<div className="item__outer">
							<div className="item__inner">
								<div className="item__text-box">
									{/* <div className="item__img-box">
										<img src={`${poster}`} alt="poster" className="item__poster-img" />
									</div> */}

									<h1 className="item__title">{title}</h1>

									<div className="rating">
										{this.props.movieVideos && this.props.movieVideos.release_date.slice(0, 4)}{' '}
										<b>|</b>
										{this.props.movieVideos &&
											this.props.movieVideos.release_dates &&
											this.props.movieVideos.release_dates.results.length > 0 &&
											this.props.movieVideos.release_dates.results.map((certificate) =>
												certificate.iso_3166_1 === 'US'
													? certificate.release_dates[0].certification
													: null
											)}
										<b>|</b>
										{convertMinsToHrsMins(this.props.movieVideos && this.props.movieVideos.runtime)}
										<span className="social-icons">
											<TwitterShareButton url={shareUrl} quote={shareTitle}>
												<TwitterIcon
													size={32}
													round={true}
													bgStyle={{ fill: 'none' }}
													className="twitter"
												/>
											</TwitterShareButton>
											<FacebookShareButton url={shareUrl} quote={shareTitle}>
												<FacebookIcon
													size={32}
													round={true}
													bgStyle={{ fill: 'none' }}
													className="facebook"
												/>
											</FacebookShareButton>
											<WhatsappShareButton url={shareUrl} quote={shareTitle}>
												<WhatsappIcon
													size={32}
													round={true}
													bgStyle={{ fill: 'none' }}
													className="whatsapp"
												/>
											</WhatsappShareButton>
										</span>
									</div>
									{/* <div className="item-rating">
										<img src={imdb} alt="imdb" className="item-rating__imdb" />
										<span className="item-rating__rank">{vote_average}/</span>
										<span className="item-rating__ten">10</span>
										<img src={star} alt="imdb" className="item-rating__star" />
									</div> */}
									<h1 className="item__title">{name}</h1>
									<span className="item__overview">{overview}</span>

									<ItemPageFooter movies={movies} tvshow={tvshow} item={item} />
								</div>
							</div>
						</div>
					</Fade>
				</div>
				<div className="youtube-area">
					<YouTube
						videoId={
							this.props.movieVideos && this.props.movieVideos.videos.results.length
								? this.props.movieVideos.videos.results[0].key
								: 'no_trailer_found'
						}
						opts={opts}
						onReady={this._onReady}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	movieCast: selectMovieCast(state),
	movieVideos: selectMovieVideos(state),
});

export default connect(mapStateToProps)(ItemPage);
