import { API_URL, API_KEY } from '../Config/config';

const urls = [
	`https://api.themoviedb.org/3/discover/movie?api_key=24281e65b0de2214c39a6d8dddb9d825`,
	`${API_URL}search/movie?api_key=${API_KEY}&language=en-US&query=${makeid(1)}`,
	`${API_URL}search/movie?api_key=${API_KEY}&language=en-US&query=The%20Lion%20King`,
	`${API_URL}search/movie?api_key=${API_KEY}&language=en-US&query=how%20to%20train%20your%20dragon%20the`,
];


const url =
	'https://api.themoviedb.org/3/movie/527774?api_key=24281e65b0de2214c39a6d8dddb9d825&append_to_response=videos';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function fetchGrid() {
	return Promise.all(
		urls.map((items) => {
			return fetch(items).then((response) => response.json());
		})
	);
}

export function fetchBanner() {
	return fetch(url).then((response) => response.json());
}
